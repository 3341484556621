import React, { Component } from "react";
import gsap from "gsap";
import Splitting from "splitting";
import "./Work.scss";

export default class Work extends Component {

  constructor(props) {
    super(props);
    this.picture = React.createRef();
    this.pictureInner = React.createRef();
    this.captionTitle = React.createRef();
    this.captionNumber = React.createRef();
    this.captionTexts = React.createRef();
    this.captionDate = React.createRef();
    this.inStack = true;
  }

  componentDidMount() {
    const titleItemSlitted = Splitting({ target: this.captionTitle.current, by: "chars" });

    this.captionChars = titleItemSlitted[0].chars;
    this.captionCharsTotal = this.captionChars.length;
    this.imgRect = this.picture.current.getBoundingClientRect();

    this.props.instance(this);
  }

  onHandleEnter = () => {
    if (this.inStack) return false;
    gsap
      .timeline({ defaults: { duration: 1, ease: 'expo' } })
      .to(this.picture.current, { scale: 0.95 })
      .to(this.pictureInner.current, { scale: 1.2 }, 0)
      .to(this.captionChars, {
        x: pos => -10 * (Math.floor(this.captionCharsTotal / 2) - pos),
        stagger: { from: 'center' }
      }, 0);
  }

  onHandleLeave = () => {
    if (this.inStack) return false;
    gsap
      .timeline({ defaults: { duration: 1, ease: 'expo' } })
      .to([this.picture.current, this.pictureInner.current], { scale: 1 })
      .to(this.captionChars, { x: 0 }, 0);
  }

  render() {
    const { title, client, description, technologies, thumbnail, url } = this.props.data;
    return (
      <a href={url} rel="noreferrer" target="_blank" className="works__elt">
        <div className="works__elt__img hoverable" ref={this.picture} onMouseEnter={this.onHandleEnter}
          onMouseLeave={this.onHandleLeave}>
          <div className="works__elt__img-inner" ref={this.pictureInner}
            style={{ backgroundImage: `url(${process.env.REACT_APP_API_URL + thumbnail.data.attributes.formats.large.url})` }}></div>
        </div>
        <div className="works__elt__caption">
          <h2 className="works__elt__caption-title" data-scroll="true" data-scroll-speed="2" data-splitting={true}
            ref={this.captionTitle}>{title}</h2>
          <span className="works__elt__caption-number" ref={this.captionNumber}>{this.props.displayNumber}</span>
          <p className="works__elt__caption-text" ref={this.captionTexts}>{client}</p>
          <div className="works__elt__caption-date" ref={this.captionDate}>
            {
              technologies.data.map((technology) => {
                return <span className="tag">{technology.attributes.name}</span>
              })
            }
          </div>
        </div>
      </a>
    )
  }
}