import React from "react";
import styled from "styled-components";
import { WrapperStyled } from "./Wrapper";

export const GridStyled = styled(WrapperStyled)`
  display: grid;
  grid-template-columns: repeat(4, 1fr);
  column-gap: 32px;
  @media only screen and (min-width: 768px) {
    grid-template-columns: repeat(8, 1fr);
  }
  @media only screen and (min-width: 992px) {
    grid-template-columns: repeat(12, 1fr);
  }
`

const Grid = ({children}: {children: React.ReactNode}) => {
  return (
    <GridStyled>
      {children}
    </GridStyled>
  )
}

export default Grid;