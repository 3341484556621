import React from "react";
import styled, {StyledComponent} from "styled-components";

export const WrapperStyled: StyledComponent<"div", any> = styled.div`
  max-width: 1200px;
  margin: 0 auto;
  padding: 0 2rem;
`

const Wrapper = ({ children }: {children: React.ReactNode}) => {
  return (
    <WrapperStyled>
      {children}
    </WrapperStyled>
  );
};

export default Wrapper;