import React, { useRef } from "react";
import gsap from "gsap";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faArrowRight as arrow } from "@fortawesome/free-solid-svg-icons";
import './WorksListItem.scss';

function WorksListItem({ name, technologies, url }) {

  const rowWrapperRef = useRef();
  const nameRef = useRef();
  const tagsRef = useRef([]);
  const iconRef = useRef();

  const HandleMouseEnter = () => {
    gsap.killTweensOf([nameRef.current]);

    gsap.timeline()
      .addLabel('start', 0)
      .to(iconRef.current, {
        duration: 0.4,
        ease: 'power3',
        startAt: {
          rotate: 0,
          opacity: 0,
          yPercent: -100,
        },
        rotate: '-45deg',
        yPercent: 0,
        opacity: 1,
      }, 'start')
      .to(tagsRef.current, {
        duration: 0.4,
        ease: 'power3',
        startAt: {
          scale: 0.8,
          xPercent: 20
        },
        scale: 1,
        xPercent: 0,
        opacity: 1,
        stagger: -0.035
      }, 'start')
      .set(nameRef.current, { transformOrigin: '0% 50%' }, 'start')
      .to(nameRef.current, {
        duration: 0.1,
        ease: 'power1.in',
        yPercent: -100
      }, 'start')
      .to(nameRef.current, {
        duration: 0.5,
        ease: 'expo',
        startAt: {
          yPercent: 100,
          rotation: 15
        },
        yPercent: 0,
        rotation: 0
      }, 'start+=0.1');
  }

  const HandleMouseLeave = () => {
    gsap.killTweensOf([tagsRef.current, nameRef.current]);

    gsap.timeline()
      .addLabel('start')
      .to(iconRef.current, {
        duration: 0.4,
        ease: 'power4',
        rotate: 0,
        yPercent: -100,
        opacity: 0
      }, 'start')
      .to(tagsRef.current, {
        duration: 0.4,
        ease: 'power4',
        opacity: 0,
        scale: 0.8
      }, 'start')
      .to(nameRef.current, {
        duration: 0.1,
        ease: 'power1.in',
        yPercent: -100
      }, 'start')
      .to(nameRef.current, {
        duration: 0.5,
        ease: 'expo',
        startAt: {
          yPercent: 100,
          rotation: 15
        },
        yPercent: 0,
        rotation: 0
      }, 'start+=0.1');
  }

  return (
    <div className="row" onMouseEnter={HandleMouseEnter} onMouseLeave={HandleMouseLeave}>
      <a className="row-wrapper" href={url} target="_blank" rel="noreferrer" ref={rowWrapperRef}>
        <span ref={nameRef}>{name}</span>
        <div className="row-wrapper__right">
          <div className="tags">
            {technologies.map((technology, i) => {
              return <span className="tag" ref={el => tagsRef.current[i] = el}>{technology.attributes.name}</span>
            })}
          </div>
          <div className="icon" ref={iconRef}>
            <FontAwesomeIcon icon={arrow} />
          </div>
        </div>
      </a>
    </div>
  )
}

export default WorksListItem;