import { createGlobalStyle } from "styled-components";
import reset from "styled-reset";

export const GlobalStyle = createGlobalStyle`
  ${reset}
    /* Recommended styles for Splitting */
  .splitting .word,
  .splitting .char {
    display: inline-block;
  }

  /* Pseudo-element chars */
  .splitting .char {
    white-space: nowrap;
    position: relative;
  }

  .line {
    transform-origin: 0 50%;
    //padding-bottom: 0.35rem;
    white-space: nowrap;
    will-change: transform;
  }

  .mask {
    overflow: hidden;
  }

  :root {
    --font-heading: "Jost", sans-serif;
    --font-text: 'Public Sans', sans-serif;
    --color-heading: #f8f6f3;
    --color-text: #a3a8b6; //#f8f6f3
    --color-bg: #030014; //#11121d 0c0c0c
    --color-border: #2a2b3a;
    --color-link: #f8f6f3;
    --color-link-hover: #1936da;
    --cursor-stroke: none;
    --cursor-fill: #1936da;
    --cursor-stroke-width: 1px;
  }

  :root {
    font-size: 16px;
  }

  body {
    margin: 0;
    background-color: var(--color-bg);
    font-family: var(--font-text);
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
  }

  main {
    position: relative;
    width: 100%;
  }

  .grid, .wrapper {
    width: calc(100% - 64px);
    max-width: 1200px;
    margin: 0 auto;
  }

  .wrapper {
    position: relative;
  }

  h1, h2, h3, h4, h5 {
    font-family: var(--font-heading);
    color: var(--color-heading);
    margin: 0;
  }

  h1 {
    font-size: 2rem;
    font-weight: 900;
    line-height: 1.1;
    text-transform: uppercase;

    @media screen and (min-width: 375px) {
      font-size: 2.5rem;
    }
    @media screen and (min-width: 768px) {
      font-size: 3rem;
    }
    @media screen and (min-width: 1200px) {
      font-size: 4.2rem;
    }
  }

  h2 {
    font-size: 1.333rem;
    font-weight: 900;
    line-height: 1.1;
    margin: 0;

    @media screen and (min-width: 375px) {
      font-size: 1.777rem;
    }
    @media screen and (min-width: 768px) {
      font-size: 2.369rem;
    }
    @media screen and (min-width: 1200px) {
      font-size: 3.157rem;
    }
  }

  h3 {
    font-size: 1rem;
    font-weight: 500;
    line-height: 1.2;
    margin: 0;

    @media screen and (min-width: 375px) {
      font-size: 1.333rem;
    }
    @media screen and (min-width: 768px) {
      font-size: 1.777rem;
    }
    @media screen and (min-width: 1200px) {
      font-size: 2.369rem;
    }
  }

  p {
    font-size: 1rem;
    color: var(--color-text);
    line-height: 1.5rem;
  }

  a {
    text-decoration: none;
    color: var(--color-link);
    outline: none;
    transition: color 0.3s ease-in-out;
    &:hover,
    &:focus {
      color: var(--color-link-hover);
    }
  }

  .webgl {
    position: fixed;
    top: 0;
    left: 0;
    z-index: -1;
  }

  /*---- Fix the issue Locomotive on Google chrome ----*/
  html.has-scroll-smooth {
    backface-visibility: hidden;
    transform: translateZ(0);
  }

  @media (min-width: 768px) {
    [data-load-container] {
      position: fixed;
      top: 0;
      right: 0;
      bottom: 0;
      left: 0;
      width: 100vw;
    }
  }
`