import styled from "styled-components";

export const ExpertiseStyled = styled.div`
  padding: 1.5rem 1.5rem 2rem;
  display: grid;
  gap: 1.5rem;
  background: var(--color-bg);
  align-content: start;
  grid-template-rows: auto 1fr auto;

  .expertise-img {
    width: 100%;
    aspect-ratio: 1;
    border-radius: 1.6rem;
    position: relative;
    overflow: hidden;
    display: grid;
    place-items: center;
    --x: 0px;
    --y: 0px;
  }

  .expertise-img::after {
    content: '';
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background: radial-gradient(rgb(23, 24, 37) 40%, rgb(102, 51, 238) 50%, rgb(142, 100, 255), rgb(249, 38, 114));
    mix-blend-mode: darken;
  }

  .expertise-img-deco {
    position: absolute;
    top: 0;
    left: 0;
    height: 100%;
    width: 100%;
    font-family: "input-mono", monospace;
    font-size: 0.85rem;
    word-wrap: break-word;
    line-height: 1.15;
    color: #fff;
    opacity: 0;
    -webkit-mask-image: radial-gradient(300px circle at var(--x) var(--y), black 20%, rgba(0, 0, 0, 0.25), transparent);
    mask-image: radial-gradient(300px circle at var(--x) var(--y), black 20%, rgba(0, 0, 0, 0.25), transparent);
  }

  .expertise-img svg {
    width: 75%;
    max-width: 180px;
    position: relative;
    z-index: 1;
    fill: var(--color-heading);
  }

  .expertise-label {
    line-height: 1.4;
    margin: 0;
  }

  .expertise-tag {
    align-self: end;
    justify-self: start;
    border: 1px solid var(--color-border);
    border-radius: 1em;
    line-height: 0.75;
    padding: 0.5rem 0.75rem;
    font-size: 0.85rem;
    color: var(--color-heading);
  }
`