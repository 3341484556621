import React, { useEffect, useRef, useState } from 'react';
import GlobeBlob from "../webgl/GlobeBlob";
import GlobeScene from "../webgl/GlobeScene";

const Globe = ({onChildInitialize}) => {

  const globeRef = useRef(null);
  const [instance, setInstance] = useState(null);

  useEffect(() => {
    const blob = new GlobeBlob(4.5);
    blob.position.set(0, 0, 5);
    blob.rotation.set(0, 0, 0);

    setInstance(blob);

    let globeScene = new GlobeScene(globeRef.current)
    globeScene.scene.add(blob);

    onChildInitialize(blob);
  }, []);

  return <div className="globe-container" ref={globeRef}/>;
}

export default Globe;