import styled from "styled-components";

export const HeaderStyled = styled.header`
  width: 100%;
  position: fixed;
  top: 0;
  left: 0;
  z-index: 9998;
  padding: 24px 0;
  background-color: var(--color-bg);
  transform: translateY(-100%);
  @media only screen and (min-width: 554px) {
    background-color: transparent;
  }
  @media only screen and (min-width: 1200px) {
    padding: 32px 0;
  }

  .header-centered {
    width: calc(100% - 64px);
    margin: 0 auto;
    display: flex;
    align-items: center;
    justify-content: space-between;

    &__link {
      display: flex;
      align-items: center;

      &__logo {
        display: block;
        width: 16px;
        height: 16px;
        fill: var(--color-heading);
        transition: all 0.3s ease-in-out;
      }

      &:hover {
        .header-centered__link__logo {
          fill: var(--color-link-hover);
        }
      }
    }
  }
  
  .burger {
    display: block;
    height: 22px;
    width: 22px;
    position: relative;
    cursor: pointer;

    .burger-center {
      width: 22px;
      position: absolute;
      top: 50%;
      left: 50%;
      transform: translate(-50%, -50%);

      .burger-center__line {
        display: block;
        width: 100%;
        height: 1px;
        background-color: var(--color-heading);
        margin-bottom: 7px;

        &:first-child {
          transform-origin: right top;
          transition: all 0.4s ease-in-out;
        }

        &:last-child {
          margin-bottom: 0px;
          transform-origin: left top;
          transition: all 0.4s ease-in-out;
        }
      }
    }

    &:hover,
    &:focus {
      .burger-center__line {
        transform: scaleX(0.8);
      }
    }

    &--active {
      .burger-center {
        span.burger-center__line:first-child {
          margin-top: 0px;
          transform-origin: center center !important;
          transform: scaleX(1) rotate(45deg) !important;
        }

        span.burger-center__line:last-child {
          margin-top: -8px;
          transform-origin: center center !important;
          transform: scaleX(1) rotate(-45deg) !important;
        }
      }
    }
  }

`