import React, { useEffect, useRef } from "react";
import { Link } from 'react-router-dom';
import gsap from "gsap";
import { HeaderStyled } from "./Header.styled"

const Header = ({ displayNav }) => {

  const burgerRef = useRef();
  const headerRef = useRef(null);

  useEffect(() => {
    const tl = gsap.timeline({ delay: 0.25 });
    tl.to(headerRef.current, {
        duration: 1.2,
        ease: 'expo',
        y: '0%',
      });
  }, []);

  const onHandleClick = () => {
    burgerRef.current.classList.toggle("burger--active");
    burgerRef.current.classList.contains("burger--active") ? displayNav(true) : displayNav(false)
  }

  const onHomeClick = () => {
    burgerRef.current.classList.remove("burger--active");
    displayNav(false)
  }

  return (
    <HeaderStyled ref={headerRef}>
      <div className="header-centered">
        <Link className="header-centered__link" to="/" onClick={onHomeClick}>
          <svg className="header-centered__link__logo" width="32" height="32" viewBox="0 0 32 32">
            <path d="M14.8569 14.7146C14.0533 16.1224 13.2589 17.5391 12.4644 18.9469C11.2682 21.0587 10.0811 23.1616 8.8849 25.2734C7.95349 26.91 7.01296 28.529 6.08155 30.1657C5.99936 30.3064 5.93544 30.412 5.73455 30.412C3.87174 30.4032 1.99979 30.4032 0.136972 30.4032C0.100446 30.4032 0.0730516 30.3856 0 30.368C5.33277 20.9179 10.6655 11.4853 16.0348 2C16.391 2.62473 16.7197 3.19667 17.0393 3.7686C18.7651 6.82186 20.5001 9.88392 22.226 12.9372C23.6779 15.4977 25.1206 18.0582 26.5725 20.6187C26.7734 20.9707 26.7734 20.9707 26.3351 20.9707C24.6001 20.9707 22.8652 20.9619 21.1393 20.9795C20.8106 20.9795 20.6645 20.8651 20.5092 20.5923C19.0391 17.9614 17.5507 15.3393 16.044 12.6556C15.6239 13.3771 15.2404 14.0459 14.8569 14.7146V14.7146Z" />
            <path d="M32 30.4032H31.5036C29.8067 30.4032 28.1098 30.4032 26.4038 30.412C26.1782 30.412 26.0428 30.3683 25.9255 30.1498C25.1763 28.7866 24.4091 27.4234 23.6509 26.0602C23.4523 25.7107 23.4613 25.7107 23.8495 25.7107H29.0575C29.229 25.7107 29.3734 25.7194 29.4727 25.9029C30.285 27.3535 31.1064 28.7954 31.9278 30.2459C31.9458 30.2809 31.9639 30.3246 32 30.4032V30.4032V30.4032Z" />
          </svg>
        </Link>
        <div className="burger" onClick={onHandleClick} ref={burgerRef}>
          <div className="burger-center">
            <span className="burger-center__line" />
            <span className="burger-center__line" />
          </div>
        </div>
      </div>
    </HeaderStyled>
  )
}

export default Header;
