import styled from "styled-components";

export const ProfileStyled = styled.div`
  padding: 40px 0;

  .keys {
    margin-top: 2rem;
    .description {
      font-size: 0.875rem;
      margin-top: 1rem;
      border: 1px solid var(--color-border);
      padding: 1rem;
      span {
        display: block;
        font-size: 1rem;
        color: var(--color-heading);
      }
    }
  }

  .cover {
    display: block;
    width: 100%;
    height: auto;
    margin-top: 2rem;
    img {
      width: 100%;
      height: 100%;
      object-fit: cover;
    }
  }

  .biography {
    background-color: #1936da;
    padding: 32px 24px;

    p {
      color: var(--color-heading);
      opacity: 0.85;
    }
    
    .btn-primary {
      margin-top: 1rem;
      &:hover {
        color: var(--color-heading);
        &:before, &:after {
          background-color: var(--color-heading);
        }
      }
    }
  }

  .links {
    display: inline-flex;
    flex-wrap: wrap;
    margin: 24px 0 8px;

    .btn-primary {
      margin-bottom: 16px;
      margin-right: 16px;
    }
  }

  @media only screen and (min-width: 956px) {
    display: grid;
    grid-template-columns: repeat(10, 1fr);
    grid-template-rows: repeat(6, 1fr);
    column-gap: 1rem;
    padding: 88px 0;

    .headings {
      grid-column: 1 / 5;
      grid-row: 2 / -2;
    }

    .cover {
      grid-column: 6 / 10;
      grid-row: 1 / -1;
      margin-top: 0;
    }

    .biography {
      grid-column: 7 / -1;
      grid-row: 3 / -1;
      align-self: flex-end;
      z-index: 2;
    }
  }

  @media only screen and (min-width: 1200px) {
    grid-template-columns: repeat(12, 1fr);

    .headings {
      grid-column: 1 / 6;
      grid-row: 2 / -2;
    }
    
    .cover {
      grid-column: 7 / 12;
      grid-row: 1 / -1;
    }

    .biography {
      grid-column: 8 / -1;
      grid-row: 3 / -1;
    }
  }
`