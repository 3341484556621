// Map number x from range [a, b] to [c, d]
const imagesLoaded = require('imagesloaded');

// Linear interpolation
const lerp = (a, b, n) => (1 - n) * a + n * b;

// Gets the mouse position
const getMousePos = e => {
    return {
        x: e.clientX,
        y: e.clientY
    };
};

/**
 * Gets the height of an element without counting with the padding
 * @param {Element} el
 */
const getHeight = el => {
    const computedStyle = getComputedStyle(el);

    let elementHeight = el.clientHeight;  // height with padding
    elementHeight -= parseFloat(computedStyle.paddingTop) + parseFloat(computedStyle.paddingBottom);
    return elementHeight;
};

const calcWinsize = () => {
    return {width: window.innerWidth, height: window.innerHeight};
};

const getRandomInteger = (min, max) => Math.floor(Math.random() * (max - min + 1) + min);

// Preload images
const preloadImages = (selector = 'img') => {
    return new Promise((resolve) => {
        const imgLoad = imagesLoaded(document.querySelectorAll(selector), {background: true})
        imgLoad.on('progress', function (instance, image) {
            let result = image.isLoaded ? 'loaded' : 'broken';
            console.log('image is ' + result + ' for ' + image.img.src);
        });
        imgLoad.on('fail', function (instance) {
            console.log('FAIL - all images loaded, at least one is broken');
        });
        imgLoad.on('done', function (instance) {
            console.log('DONE  - all images have been successfully loaded');
            resolve();
        });
    });
};

const wrapLines = (elems, wrapType, wrapClass) => {
    elems.forEach(char => {
        // add a wrap for every char (overflow hidden)
        const wrapEl = document.createElement(wrapType);
        wrapEl.classList = wrapClass;
        char.parentNode.appendChild(wrapEl);
        wrapEl.appendChild(char);
    });
}

export {
    lerp,
    getMousePos,
    getHeight,
    calcWinsize,
    wrapLines,
    getRandomInteger,
    preloadImages
};