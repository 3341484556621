import { useState, createContext } from "react";
import { createRoot } from 'react-dom/client';
import { BrowserRouter as Router, Routes, Route } from "react-router-dom";
import App from './views/App/App';
import About from "./views/About/About";
import Works from './views/Works/Works';
import Error from './views/Error/Error';
import Header from "./components/Header/Header";
import Navigation from "./components/Navigation/Navigation";
import { GlobalStyle } from "./GlobalStyle";


const navigation = {
    isOpen: false
}

export const NavigationContext = createContext(navigation.isOpen);

const RouterApp = () => {

    const [showNavigation, setShowNavigation] = useState(false);
    const displayNav = (show: boolean) => setShowNavigation(show);

    return (
        <Router>
            <NavigationContext.Provider value={showNavigation}>
                <GlobalStyle />
                <Header displayNav={displayNav}/>
                {
                    showNavigation ? <Navigation displayNav={displayNav}/> : null
                }
                <Routes>
                    <Route path="/" element={<App />}/>
                    <Route path="about" element={<About />} />
                    <Route path="works" element={<Works />} />
                    <Route path="*" element={<Error />} />
                </Routes>
            </NavigationContext.Provider>
        </Router>
    )
}

const domNode = document.getElementById('root')!;
const root = createRoot(domNode);
root.render(<RouterApp />);
