import React, {Fragment, useRef, useEffect} from "react";
import {NavLink} from "react-router-dom";
import gsap from "gsap";
import "./Navigation.scss";

const routes = [
    {"name": "Home", "path": "/", "number": "01"},
    {"name": "About", "path": "/about", "number": "02"},
    {"name": "Works", "path": "/works", "number": "03"},
]

function Navigation({displayNav}) {

    const navigationRef = useRef();

    useEffect(() => {
        openNavigation();
    }, [])

    const openNavigation = () => {

        const lines = navigationRef.current.querySelectorAll(".line");
        return gsap.timeline({defaults: {duration: 1.2, ease: 'expo'}})
            .set(lines, {
                y: '100%',
                rotate: 15,
                duration: .1,
                ease: "power2"
            })
            .to(navigationRef.current, {
                startAt: {autoAlpha: 0},
                autoAlpha: 1,
                ease: 'power2',
                duration: .2
            })
            .to(lines, {
                y: '0%',
                rotate: 0,
                stagger: 0.04,
            });
    }

    const closeNavigation = () => {

        const lines = navigationRef.current.querySelectorAll(".line");
        document.querySelector(".burger").classList.remove("burger--active");

        return gsap.timeline({
            defaults: {duration: 0.7, ease: 'power2'}
        })
            .to(lines, {
                y: '-150%',
                rotate: -5,
                stagger: 0.02,
            })
            .to(navigationRef.current, {
                autoAlpha: 0,
                ease: 'expo',
                duration: 1.2,
                onComplete: () => {
                    displayNav(false);
                }
            });
    }

    return (
        <Fragment>
            <nav className="navigation" ref={navigationRef}>
                <div className="navigation-wrapper">
                    <ul className="main-navigation">
                        {
                            routes.map(route => {
                                return (
                                    <li key={route.path} className="main-navigation__el" id={route.name.toLowerCase()}>
                                        <div className="mask">
                                            <div className="line">
                                                <NavLink as={NavLink} to={route.path} onClick={closeNavigation}>
                                                    <span className="number">{route.number}</span>
                                                    <span className="name">{route.name}</span>
                                                </NavLink>
                                            </div>
                                        </div>
                                    </li>
                                )
                            })
                        }
                    </ul>
                </div>
            </nav>
        </Fragment>
    );
}

export default Navigation;
