export const fragmentShader = `
  varying float vDistort;
  uniform float uTime;
  uniform float uIntensity;
  uniform float uAlpha;
  
  void main() {
    float distort = vDistort * uIntensity;
    vec3 baseColor = vec3(distort);
    
    vec3 electricBlue = vec3(0.18, 0.63, 1.0);
    
    float blackIntensity = 0.5;
    vec3 finalColor = mix(baseColor * blackIntensity, baseColor + electricBlue, electricBlue);
    
    gl_FragColor = vec4(finalColor, uAlpha);
  }
`;