import React from "react";
import {Link} from "react-router-dom";
import "./Error.scss";

const Error = () => {
    return (
        <section className="section-error">
            <div className="grid">
                <div className="centered-block">
                    <h1>404</h1>
                    <p>Sorry, this page doesn't exist.</p>
                    <Link className="btn-primary" to="/">Back to home</Link>
                </div>
            </div>
        </section>
    )
}

export default Error;