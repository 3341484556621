import React from "react";
import styled from "styled-components";
import Social from "./Social";

const FooterStyled = styled.footer`
  display: flex;
  flex-direction: column;
  align-items: center;
  background-color: var(--color-bg);
  border-top: 1px solid var(--color-border);
  padding: 30px;

  .copyright {
    display: block;
    font-size: 0.75rem;
    line-height: 1.25;
    margin-top: 8px;
    text-align: center;
    color: var(--color-text);
  }
`

const Footer = () => {
  return (
    <FooterStyled>
      <Social />
      <small className="copyright">@All rights reserved - Antony Paquay 2023</small>
    </FooterStyled>
  )
}

export default Footer;