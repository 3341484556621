import * as THREE from 'three';
import { vertexShader } from "./shaders/vertex";
import { fragmentShader } from "./shaders/fragment";

const settings = {
  size: 4.5,
  speed: 0.2,
  density: 1.5,
  strength: 0.2,
  frequency: 3.0,
  amplitude: 6.0,
  intensity: 7.0,
  offset: Math.PI * 2
};

export default class GlobeBlob extends THREE.Object3D {
  constructor(size, speed, color, density, strength, offset) {
    super();

    this.geometry = new THREE.IcosahedronBufferGeometry(settings.size, 64);
    this.material = new THREE.ShaderMaterial({
      vertexShader,
      fragmentShader,
      uniforms: {
        uTime: { value: 0 },
        uSpeed: { value: settings.speed },
        uNoiseDensity: { value: settings.density },
        uNoiseStrength: { value: settings.strength },
        uFrequency: { value: settings.frequency },
        uOffset: { value: settings.offset },
        uAmplitude: { value: settings.amplitude },
        uIntensity: { value: settings.intensity },
        uAlpha: { value: 0.0 },
      },
      defines: {
        PI: Math.PI
      },
      // wireframe: true,
      transparent: true,
    });

    this.mesh = new THREE.Mesh(this.geometry, this.material);
    this.add(this.mesh);
  }
}