import React, { useEffect, useRef, useState, useMemo, useCallback } from "react";
import { Link } from "react-router-dom"
import SplitType from 'split-type'
import { wrapLines } from "../../utils"
import gsap from "gsap";
import "../../components/common/Buttons.scss";
import Globe from "../../components/Globe";
import { AppGridStyled } from "./App.styled";

function App() {

  const timelineRef = useRef(null);
  const titleRef = useRef();
  const descriptionRef = useRef();
  const linkRef = useRef();

  const [SplitTypeInstances, setSplitTypeInstances] = useState([]);
  const [lines, setLines] = useState([]);
  const [blob, setBlob] = useState(null);

  useEffect(() => {
    const adjustHeight = () => {
      const windowHeight = window.innerHeight;
      const gridElement = document.getElementById('grid');
      if (gridElement) {
        gridElement.style.height = windowHeight + 'px';
      }
    };

    lines.length = 0;
    SplitTypeInstances.length = 0;

    const tl = gsap.timeline({ paused: true, delay: 0.25 })
    const elements = [titleRef.current, descriptionRef.current, linkRef.current];

    for (const el of elements) {
      const SplitTypeInstance = new SplitType(el, { types: 'lines' });
      // wrap the lines (div with class .mask)
      // the inner child will be the one animating the transform
      wrapLines(SplitTypeInstance.lines, 'div', 'mask');

      lines.push(SplitTypeInstance.lines);
      SplitTypeInstances.push(SplitTypeInstance);
    }

    setLines([...lines]);
    setSplitTypeInstances([...SplitTypeInstances]);

    timelineRef.current = tl;
    window.addEventListener('load', adjustHeight);
    window.addEventListener('resize', adjustHeight);

    return () => {
      window.removeEventListener('load', adjustHeight);
      window.removeEventListener('resize', adjustHeight);
    };
  }, []);

  useEffect(() => {
    if (blob) {
      timelineRef.current.add(animateText());
      timelineRef.current.add(animateGlobe(), '-=1');
      timelineRef.current.play();
    }
  }, [blob]);

  const animateText = () => {

    gsap.killTweensOf(lines);
    linkRef.current.classList.remove('btn-primary--hide');
    linkRef.current.style.transition = 'all 0.3s ease-out'

    return gsap.timeline({ defaults: { duration: 1.2, ease: 'expo' } })
      .set(lines, {
        y: '150%',
        rotate: 15
      })
      .set([titleRef.current, descriptionRef.current, linkRef.current], {
        opacity: 1,
      })
      .to(lines, {
        y: '0%',
        rotate: 0,
        stagger: 0.04,
        onComplete: () => {
          revertSplitType();
        }
      });
  }

  const animateGlobe = () => {

    const tl = gsap.timeline({
      defaults: {
        duration: 2,
        ease: 'power3.inOut'
      },
    });

    tl
      .to(blob.position, { z: 0, })
      .to(blob.mesh.material.uniforms.uAlpha, {
        value: 1.0,
        stagger: 0.2,
      }, 0);

    return tl;
  }

  const out = () => {
    gsap.killTweensOf(lines);
    return gsap.timeline({
      defaults: { duration: 0.7, ease: 'power2' }
    })
      .to(lines, {
        y: '-150%',
        rotate: -5,
        stagger: 0.02,
      });
  }

  const revertSplitType = () => {
    for (const instance of SplitTypeInstances) {
      instance.revert();
    }
  }

  const handleChildStateChange = (value) => {
    setBlob(value);
  };

  return (
    <>
      <Globe onChildInitialize={handleChildStateChange} />
      <AppGridStyled id="grid">
        <div className="block-title">
          <h1 ref={titleRef}>Crafting fluid interfaces</h1>
        </div>
        <div className="block-content">
          <p ref={descriptionRef}>
            Hello there 👋 I'm a Front-end Engineer specializing in Javascript, based in Namur, Belgium.
          </p>
          <Link
            className="btn-primary btn-primary--hide"
            ref={linkRef}
            to="/works"
          >
            Explore my works
          </Link>
        </div>
      </AppGridStyled>
    </>
  )
}

export default App;