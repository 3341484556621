import React, { Component } from "react";
import gsap from "gsap";
import { Strapi } from "../../config/axios-config";
import LocomotiveScroll from 'locomotive-scroll';
import "locomotive-scroll/dist/locomotive-scroll.css"
import Splitting from "splitting";
import { calcWinsize, getRandomInteger, preloadImages } from '../../utils';
import "./Works.scss";
import Work from "./Work";
import WorksList from "../../components/WorksList/WorksList";


class Works extends Component {

  constructor(props) {
    super(props);
    this.containerRef = React.createRef();
    this.titleRef = React.createRef();
    this.galleryItems = [];
    this.state = {
      works: [],
      isLoading: true
    };
  }

  componentDidMount() {

    this.splitPageTitle();

    Strapi.get('/api/projects?populate=*').then((works) => {

      this.setState({ works: works.data.data, isLoading: false });
      preloadImages('.works__elt__img-inner').then(() => {

        // Initialize the Locomotive scroll
        this.scroll = new LocomotiveScroll({
          el: this.containerRef.current,
          smooth: true,
          tablet: {
            breakpoint: 768,
            smooth: true
          }
        });

        // Calculate the viewport size
        this.winsize = calcWinsize();
        window.addEventListener('resize', () => this.winsize = calcWinsize());


        setTimeout(() => {
          this.startAnimation();
        }, 0.8);

      });
    }).catch((error) => {
      console.log("Error", error);
    })
  }

  componentWillUnmount() {
    this.scroll.destroy();
  }

  splitPageTitle = () => {
    // Split the title from the page
    const titleSlitted = Splitting({ target: this.titleRef.current, by: "chars" });

    this.titleChars = titleSlitted[0].chars;
    this.titleCharsTotal = this.titleChars.length;
  }

  startAnimation() {
    const timeline = gsap.timeline({ delay: .25 }).to(this.titleRef.current, {
      duration: 1,
      ease: 'expo',
      opacity: 1,
      y: '0%',
    }, 0);

    // now let's center the images (stack)
    for (const [pos, item] of this.galleryItems.entries()) {
      timeline.set(item.picture.current, {
        x: this.winsize.width / 2 - item.imgRect.left - item.imgRect.width / 2,
        y: this.winsize.height / 2 - item.imgRect.top - item.imgRect.height / 2,
        scale: 0.6,
        rotation: getRandomInteger(-10, 10),
        opacity: 1,
        delay: 0.2 * pos
      }, 0);

      // for the first image, we set a high scale for the inner image element
      // later we will animate this scale value together with the scale value of the outer image
      if (pos === 0) {
        timeline.set(item.pictureInner.current, {
          scale: 1.8
        }, 0);
      }
    }

    const [firstImage, ...otherImages] = this.galleryItems.map(el => el.picture.current);

    timeline
      .addLabel('startAnimation', '+=0')
      // allow scroll and update the locomotive scroll
      .add(() => {
        this.containerRef.current.classList.remove('noscroll');
        this.scroll.update();
      }, 'startAnimation')

      // animate the main title characters out and fade them out too
      .to(this.titleChars, {
        duration: 1,
        ease: 'expo',
        x: (pos, target) => {
          return -40 * (Math.floor(this.titleCharsTotal / 2) - pos);
        },
        opacity: 0,
        stagger: { from: 'center' }
      }, 'startAnimation')

      // the other images in the stack will animate its translation values randomly
      .to(otherImages, {
        duration: 1,
        ease: 'power3',
        x: () => '+=' + getRandomInteger(-200, 200) + '%',
        y: () => '+=' + getRandomInteger(-200, 200) + '%',
        opacity: 0,
        rotation: () => getRandomInteger(-20, 20)
      }, 'startAnimation')
      // and then we make them appear in their final position in the grid
      .to(otherImages, {
        duration: 0.5,
        ease: 'expo',
        startAt: {
          x: 0,
          y: 0,
          rotation: 0,
          scale: 0.8
        },
        scale: 1,
        opacity: 1
      })

      // the first image will now animate to it's final position
      .to(firstImage, {
        duration: 1.2,
        ease: 'expo',
        x: 0,
        y: 0,
        scale: 1,
        rotation: 0,
        opacity: 1
      }, 'startAnimation')
      .to(this.galleryItems[0].pictureInner.current, {
        duration: 1.2,
        ease: 'expo',
        scale: 1
      }, 'startAnimation');

    // finally, animate the gallery item's content elements (title, number and texts)
    for (const [pos, item] of this.galleryItems.entries()) {
      timeline
        .add(() => item.inStack = false, 'startAnimation+=1')
        .to(item.captionChars, {
          duration: 2,
          ease: 'expo',
          startAt: {
            opacity: 0,
            x: pos => -40 * (Math.floor(item.captionCharsTotal / 2) - pos)
          },
          x: 0,
          opacity: 1,
          stagger: { from: 'center' }
        }, 'startAnimation')
        .to([item.captionNumber.current, item.captionTexts.current, item.captionDate.current], {
          duration: 1,
          ease: 'power1',
          startAt: { opacity: 0 },
          opacity: 1,
          stagger: 0.04
        }, 'startAnimation+=0.3')
    }

  }

  getInstance = (instance) => {
    this.galleryItems.push(instance);
  }

  render() {
    let featuredWorks;
    const { isLoading, works } = this.state;
    if (!isLoading) {
      featuredWorks = works.map((work, key) => {
        if (work.attributes.featured) {
          return <Work
            key={ key }
            instance={ this.getInstance }
            data={ work.attributes }
            displayNumber={ `0${ key + 1 }` }
          />
        }
      });
    }
    return (
      <div data-load-container="true">
                <div className="section-works" ref={ this.containerRef }>
                    <div className="container-scroll">


                        <h2 className="title" ref={ this.titleRef }>
                            Works
                        </h2>

                      { isLoading &&
                        <div className="loader">
                                <span className="loader-line" />
                            </div>
                      }


                      <div className="works">{ featuredWorks }</div>
                    </div>
                    <div className="wrapper">
                        { !this.state.isLoading &&
                          <WorksList data={ this.state.works.filter(work => !work.attributes.featured) } /> }
                    </div>
                </div>
            </div>
    )
  }
}

export default Works;