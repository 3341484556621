import React, { forwardRef } from 'react';
import { ExpertiseStyled } from "./Expertise.styled";

interface ExpertiseProps {
  label: string;
  tag: string;
  icon: any;
  ref?: React.ForwardedRef<any>;
}

const Expertise: React.FC<ExpertiseProps> = forwardRef((
  { label, tag, icon: Icon }, ref
) => {
  return (
    <ExpertiseStyled ref={ ref }>
			<div className="expertise-img">
				<div className="expertise-img-deco"></div>
        <Icon size={ 48 } />
			</div>
			<p className="expertise-label">{ label }</p>
			<span className="expertise-tag">{ tag }</span>
		</ExpertiseStyled>
  );
});

export default Expertise;