import React from "react";
import { ProfileStyled } from "./Profile.styled";
// @ts-ignore
import antonyImgLg from "../../assets/img/antony-test.jpg";

const Profile = () => {
  return (
    <div>
      <ProfileStyled>
        <div className="headings">
          <h2>I am always looking new challenges</h2>
          <div className="personal-information">
            <div className="keys">
              <p className="description">
                Current position: <span>Versett Europe (CBTW)</span>
              </p>
              <p className="description">
                Location: <span>Namur (Belgium 🇧🇪)</span>
              </p>
              <p className="description">
                Availability: <span>Ready for new missions</span>
              </p>
            </div>
          </div>
        </div>
        <picture className="cover">
          <img src={antonyImgLg} alt="Antony Paquay" />
        </picture>
        <div className="biography">
          <p>Got an innovative project or ready to embrace digital transformation for your business? Let's explore the possibilities together!</p>
          <a href="mailto:antony.paquay@gmail.com" className="btn-primary" target="_blank">Let's talk!</a>
        </div>
      </ProfileStyled>
    </div>
  )
}

export default Profile;