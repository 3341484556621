import styled from "styled-components";

export const AppGridStyled = styled.section`
  display: grid;
  grid-template-columns: repeat(4, 1fr);
  grid-template-rows: 32px repeat(4, 1fr) 32px;
  width: calc(100% - 64px);
  height: 100vh;
  max-width: 1200px;
  margin: 0 auto;

  @media only screen and (min-width: 768px) {
    grid-template-columns: repeat(8, 1fr);
  }

  @media only screen and (min-width: 992px) {
    grid-template-columns: repeat(12, 1fr);
    grid-template-rows: 64px repeat(4, 1fr) 64px;
  }

  .block-title {
    grid-column: 1 / 5;
    grid-row: 4 / 5;
    justify-self: stretch;
    align-self: center;
    @media only screen and (min-width: 554px) {
      grid-column: 1 / 4;
    }
    @media only screen and (min-width: 768px) {
      grid-column: 1 / 6;
    }
    @media only screen and (min-width: 992px) {
      grid-column: 2 / 7;
    }
    @media only screen and (min-width: 1200px) {
      grid-column: 1 / 7;
    }
  }

  .block-content {
    grid-column: 1 / 5;
    grid-row: 5 / 6;
    justify-self: stretch;
    align-self: center;
    p {
      margin: 0;
      line-height: 24px;
      color: var(--color-heading);
    }
    .btn-primary {
      margin-top: 16px;
    }
    @media only screen and (min-width: 481px) {
      grid-column: 1 / 4;
    }
    @media only screen and (min-width: 592px) {
      grid-column: 1 / 3;
    }
    @media only screen and (min-width: 768px) {
      grid-column: 6 / span 3;
    }
    @media only screen and (min-width: 992px) {
      grid-column: 8 / -2;
    }
    @media only screen and (min-width: 1200px) {
      grid-column: 9 / -1;
    }
  }

  // Prevent display before GSAP animation
  h1, p, .btn-primary {
    opacity: 0;
  }

`
