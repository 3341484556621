import React from "react";
import WorksListItem from "./WorksListItem";

function WorksList({data}) {
    return (
        <div className="experiences" style={{padding: '128px 0'}}>
            {
                data.map((key, index) => {
                    return (
                        <WorksListItem
                            key={key.id}
                            name={key.attributes.title}
                            technologies={key.attributes.technologies.data}
                            url={key.attributes.url}
                        />
                    )
                })
            }
        </div>
    )
}

export default WorksList;