import React, { createRef, useEffect, useMemo, useRef } from 'react';
import { Link } from "react-router-dom";
import gsap from "gsap";
import SplitType from "split-type";
import { wrapLines } from "../../utils";
import Profile from "../../components/Profile/Profile";
import Footer from "../../components/Footer";

import { AboutGrid, ExpertisesGridStyled } from "./About.styled";
import Expertise from "../../components/Expertise/Expertise";
import Wrapper from "../../components/Wrapper";
import {
  Html5Original,
  JavascriptOriginal,
  ReactOriginal,
  NextjsOriginalWordmark,
  NodejsOriginal,
  ThreejsOriginal
} from 'devicons-react';

const data = [
  {
    id: 1,
    label: 'UI integration based on CSS architecture (BEM), a mobile-first approach, compliance with W3C standards, and adherence to accessibility/SEO guidelines.',
    icon: Html5Original,
    tag: 'Web Integration',
  },
  {
    id: 2,
    label: 'Creation of the front-end based on JavaScript/TypeScript language, incorporating principles of functional or object-oriented programming.',
    icon: JavascriptOriginal,
    tag: 'Front-End Development',
  },
  {
    id: 3,
    label: 'Create high-performing SPAs with React.js, Axios for API handling, and advanced tools like React Router and Redux for optimized state management.',
    icon: ReactOriginal,
    tag: 'React Development',
  },
  {
    id: 4,
    label: 'Create advanced full-stack web applications with Next.js, utilizing its server-side rendering and static website generation for optimal performance.',
    icon: NextjsOriginalWordmark,
    tag: 'Next.js Development',
  },
  {
    id: 5,
    label: 'Develop scalable full-stack solutions using Node.js, Express.js, and NoSQL database (Mongoose, MongoDB) for building powerful APIs and web applications.',
    icon: NodejsOriginal,
    tag: 'Backend Development',
  },
  {
    id: 6,
    label: 'Creation of more immersive interfaces with animations using the GSAP library, data visualization with d3.js, chartist.js, and 3D rendering with Three.js.',
    icon: ThreejsOriginal,
    tag: 'Other stuffs',
  },
]

const About = () => {

  const containerRef = useRef(null);
  const titleRef = useRef(null);
  const descriptionRef = useRef(null);
  const linkRef = useRef(null);
  const expertisesRefs = useRef<any[]>([]);

  const splitInstances = useRef<{ lines: any[], instances: any[] }>({
    lines: [],
    instances: []
  });

  useEffect(() => {

    const elements = [titleRef.current, descriptionRef.current, linkRef.current];
    let splitTextInstancesArr: any[] = [];
    let lines: any[] = [];

    elements.forEach((item) => {
      const SplitText = new SplitType(item!, { types: "lines" });
      wrapLines(SplitText.lines, "div", "mask");

      splitTextInstancesArr.push(SplitText)
      lines.push(SplitText.lines);
    });

    splitInstances.current = {
      lines: lines,
      instances: splitTextInstancesArr
    }

    textReveal(splitInstances.current.lines);
  }, []);

  const textReveal = (textLinesElts: any[]) => {


    gsap.killTweensOf(textLinesElts);
    return gsap.timeline({ defaults: { delay: 0.25, duration: 1.2, ease: 'expo' } })
      .set(expertisesRefs.current, {
        y: "35%",
        opacity: 0
      })
      .addLabel('startAnimation', '+=0')
      .to(textLinesElts, {
        y: '0%',
        rotate: 0,
        onComplete: () => {
          revertSplitType();
        }
      })
      .to(expertisesRefs.current, {
        y: "0%",
        opacity: 1,
        stagger: 0.04,
      }, 'startAnimation')
  }

  const revertSplitType = () => {
    for (const instance of splitInstances.current.instances) {
      instance.revert();
    }
  }

  const createElements = () => {
    let elements: any[] = [];
    data.forEach((i) => {
      elements.push(
        <Expertise
          key={ i.id }
          ref={ (ref) => expertisesRefs.current.push(ref) }
          label={ i.label }
          icon={ i.icon }
          tag={ i.tag }
        />
      );
    })
    return elements;
  };

  return (
    <div ref={ containerRef }>
      <AboutGrid>
        <div className="content">
          <h1 ref={ titleRef }>I specialize in crafting unique and fluid interfaces for businesses.</h1>
          <p className="description" ref={ descriptionRef }>
            Hi, I'm Antony, a Front-End Engineer specializing in JavaScript. I love building dynamic and user-friendly web applications. I keep up with the latest tools and frameworks to create strong and scalable solutions. I enjoy collaborating and learning in innovative projects.
          </p>
          <Link to="/works" className="btn-primary" ref={ linkRef }>Explore my works</Link>
        </div>
      </AboutGrid>
      <Wrapper>
        <ExpertisesGridStyled>
          { createElements() }
        </ExpertisesGridStyled>
        <Profile />
      </Wrapper>
      <Footer />
    </div>
  )
}

export default About;