import styled from "styled-components";
import { GridStyled } from "../../components/Grid";

export const AboutGrid = styled(GridStyled)`
  padding: 30vh 2rem 0;

  .line {
    transform: translateY(150%) rotate(15deg);
  }

  .content {
    display: flex;
    flex-direction: column;
    align-items: center;
    text-align: center;
    grid-column: 1 / -1;

    h1 {
      font-weight: 600;
      line-height: 1.2;
      text-transform: none;
    }

    .description {
      margin: 2rem 0;
      max-width: 500px;
    }
  }

  @media only screen and (min-width: 768px) {
    .content {
      grid-column: 1 / -1;
    }
  }
  @media only screen and (min-width: 992px) {
    align-content: center;
  }
  @media only screen and (min-width: 1200px) {
    padding: 30vh 0 0;
    .content {
      grid-column: 1 / -1;
    }
  }
`
export const ExpertisesGridStyled = styled.div`
  display: grid;
  margin: 10vh 0;
  grid-template-columns: 1fr;
  border: 1px solid var(--color-border);
  background: var(--color-border);
  gap: 1px;
  @media screen and (min-width: 33em) {
    grid-template-columns: repeat(2, 1fr);
  }
  @media screen and (min-width: 53em) {
    grid-template-columns: repeat(3, 1fr);
  }
`